import "./App.css";
import axios from "axios";
import image from "./image/Evolution.png";
import { Button, Form, Input, Modal, Space, message } from "antd";
import { useState, useEffect } from "react";
const URL = "https://api.cash-5656.com";
// const adminname = 'woongpay'
// const URL = "http://localhost:2009";
const admin = {
  username: "woongpay",
  password: "a12345678",
};

const API = axios.create({
  baseURL: URL,
});
function App() {
  const [login, setLogin] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [username, setUserName] = useState("");
  const [totalBalance, setTotalBalance] = useState(0);
  const [amount, setAmount] = useState(0);
  const [modal, setModal] = useState(false);
  const [deposit, setDeposit] = useState(false);
  const [depostLoading, setDepositLoading]  = useState(false)
  const [withdrawLoading, setWithdrawLoading]  = useState(false)


  const [createAllLoading, setCreateAllLoading] = useState(false);


  const [form] = Form.useForm();


  const handleDeposit = async () => {
    try {
      if (!username) {
        messageApi.error("이름을 입력하세요!");
        return;
      }
      setDepositLoading(true)
      await API.post("/transaction-evolution", {
        username,
        type: "0",
        amount: Number(amount),

      });
      messageApi.success("Success!");
      setDepositLoading(false)

    } catch (error) {
      messageApi.error("잔액이 부족하거나 이름이 잘못됨!");
      setDepositLoading(false)

    }
  };
  const handleWitdraw = async () => {
    try {
      if (!username) {
        messageApi.error("이름을 입력하세요!");
        return;
      }
      setWithdrawLoading(true)

      await API.post("/transaction-evolution", {
        username,
        type: "1",
        amount: Number(amount),

      });
      messageApi.success("성공적인 거래 !");
      setWithdrawLoading(false)

    } catch (error) {
      messageApi.error("잔액이 부족하거나 이름이 잘못됨!");
      setWithdrawLoading(false)

    }
  };
  const Create = async (params) => {
    try {
      const { username, password } = params;
      await API.post("/create-evolution", {
        username,
        password,
      });
      messageApi.success("성공을 창조하세요!");
    } catch (error) {
      messageApi.error("중복된 아이디가 있습니다!");
    }
  };

  const launchingGame = async (value) => {
    try {
      const { data } = await API.post("/launching-evolution", {
        username: value.username,
        password: value.password,
      });
      window.open(data, "toolbar=0,location=0,menubar=0");
    } catch (error) {
      messageApi.error("아이디와 비밀번호를  확인하세요!");
    }
  };
  const getTotalBalance = async () => {
    try {
      const { data } = await API.post("/getotalbalance");
      setTotalBalance(data);
    } catch (error) {}
  };
  const handleLogin = (data) => {
    if (
      data?.username === admin.username &&
      data?.password === admin.password
    ) {
      setLogin(true);
      localStorage.setItem("login", JSON.stringify(data));
    } else {
      messageApi.error("잘못된 계정 또는 비밀번호!");
    }
  };
  useEffect(() => {
    getTotalBalance();
  }, []);
  useEffect(() => {
    const value = localStorage.getItem("login");
    if (value) {
      const data = JSON.parse(value);
      if (
        data?.username === admin.username &&
        data?.password === admin.password
      ) {
        setLogin(true);
      }
    }
  }, []);
  const CreateAll = async () => {
    try {
      setCreateAllLoading(true)
      const data = form.getFieldsValue()
      const usernamesList = [];
      for (let i = Number(data.startNumber); i <= Number(data.endNumber); i++) {
        usernamesList.push(`${username}${i}`);
      }
      const res = await API.post("/createAll",{
        username: usernamesList,
        password: data.password
      });
      setCreateAllLoading(false)
      messageApi.success('성공')

      
    } catch (error) {
      setCreateAllLoading(true)

    }
  };

  const depositAll = async () => {
    try {
      const data = form.getFieldsValue()
      setDeposit(true)
      const usernamesList = [];
      for (let i = Number(data.startNumber); i <= Number(data.endNumber); i++) {
        usernamesList.push(`${username}${i}`);
      }
      console.log(usernamesList);
      const res = await API.post("/depositAll",{
        username: usernamesList,
        amount: Number(data.amount)
        // amount: Number(10000),

      });
      messageApi.success('성공')
      setDeposit(false)

    } catch (error) {
      setDeposit(false)

    }
  };
  return (
    <>
      {contextHolder}
      <Modal
        title=""
        open={modal}
        onCancel={() => setModal(false)}
        footer={false}
      >
        <h1
          style={{
            textAlign: "center",
          }}
        >
          아이디일괄생성
        </h1>
        <Form name="basic" className="" form={form}>
          <div className="">
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "아이디를 입력해주세요",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="아이디를입력하세요 "
                onChange={(e) => setUserName(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "비밀번호를 입력하세요",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="비밀번호를 입력하세요 "
                type="password"
              />
            </Form.Item>
            <Space
              style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}
              // align="baseline"
            >
              <Form.Item
                name="startNumber"
                rules={[
                  {
                    required: true,
                    message: '들어 오세요'
                  },
                ]}
              >
                <Input size="large" type="number" placeholder="시작 번호" />
              </Form.Item>
              <h1 style={{paddingBottom: 20}}>~</h1>
              <Form.Item
                name="endNumber"
                style={{
                  // marginLeft: "80px",
                }}
                rules={[
                  {
                    required: true,
                    message: '들어 오세요'

                  },
                ]}
              >
                <Input size="large" type="number" placeholder="끝 번호" />
              </Form.Item>
            </Space>
            <Form.Item name="amount">
              <Input size="large" type="number" placeholder="금액을입력하세요"  />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 14,
                span: 16,
              }}
            >
              <Button htmlType="submit" size="large" onClick={depositAll} loading={deposit}>
                대량입금
              </Button>
              <Button
                type="primary"
                size="large"
                style={{ margin: 4 }}
                onClick={CreateAll}
                loading={createAllLoading}
              >
                생성
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      {login ? (
        <div className="App">
          <div className="">
            <img src={image} alt="" />
          </div>
          <div className="main">
            <div
              className="main_header"
              style={{
                display: "flex",
                gap: 10,
                fontSize: 20,
              }}
            >
              <strong>총보유금 : </strong>
              {Number(totalBalance.toFixed(0)).toLocaleString("en-US")}
            </div>
            <Form name="basic" className="form_main" onFinish={Create}>
              <div className="">
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "아이디를 입력해주세요",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: 250,
                    }}
                    size="large"
                    placeholder="아이디를입력하세요 "
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "비밀번호를 입력하세요",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: 250,
                    }}
                    size="large"
                    placeholder="비밀번호를 입력하세요 "
                    type="password"
                  />
                </Form.Item>
              </div>

              <Form.Item
                wrapperCol={{
                  offset: 4,
                  span: 16,
                }}
                style={{
                  width: "50px",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{
                    width: "150px",
                  }}
                >
                  아이디생성
                </Button>
                <Button
                  size="large"
                  style={{
                    width: "150px",
                    marginTop: "20px",
                  }}
                  onClick={() => setModal(true)}
                >
                  아이디일괄생성
                </Button>
              </Form.Item>
            </Form>
            <Form
              name="basic"
              className="form_main"
              style={{
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              <Form.Item
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  type="number"
                  style={{
                    width: 250,
                  }}
                  size="large"
                  placeholder="금액을입력하세요"
                  
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                style={{
                  display: "flex",
                  gap: 10,
                }}
              >
                <Button
                  type="primary"
                  loading={depostLoading}
                  onClick={() => handleDeposit()}
                  style={{
                    marginRight: 30,
                  }}
                >
                  입금
                </Button>
                <Button type="primary" onClick={() => handleWitdraw()} loading={withdrawLoading}>
                  출금
                </Button>
              </Form.Item>
            </Form>

            <Form name="basic" className="form_main" onFinish={launchingGame}>
              <div className="">
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: 250,
                    }}
                    size="large"
                    placeholder="아이디를입력하세요"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "비밀번호를 입력하세요",
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: 250,
                    }}
                    size="large"
                    placeholder="비밀번호를 입력하세요 "
                    type="password"
                  />
                </Form.Item>
              </div>

              <Form.Item
                wrapperCol={{
                  offset: 4,
                  // span: 16,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{
                    height: 104,
                  }}
                >
                  게임입장
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      ) : (
        <div className="App">
          <Form name="basic" className="" onFinish={handleLogin}>
            <h1>Sign in to Admin Portal</h1>
            <div className="">
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "사용자 이름을 입력하세요",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  size="large"
                  placeholder="username"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "암호를 입력",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  size="large"
                  placeholder="password"
                  type="password"
                />
              </Form.Item>
            </div>

            <Form.Item wrapperCol={{}}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{
                  width: "100%",
                }}
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
}

export default App;
